<template>
  <div v-click-outside="() => (isOpen = false)" :class="{ disabled: disabled }" class="custom-multiselect">
    <div @click="isOpen = true" :class="{ open: isOpen, clearable: allowEmpty }" class="custom-multiselect-wrapper" ref="block">
      <template v-if="multiply">
        <div class="custom-multiselect-wrapper__block" :class="{ alternative: alternativeStyle }" v-for="(el, key) of value" :key="key" @click.stop.prevent="removeElement(el)">
          <slot name="block" :value="el"></slot>
          <button class="custom-multiselect-remove-button">
            <svg v-svg symbol="modal-close" size="0 0 8 8" role="info"></svg>
          </button>
        </div>
      </template>
      <template v-else>
        <div class="custom-multiselect-wrapper__text">
          <slot name="block" :value="value"></slot>
        </div>
      </template>
      <button v-if="allowEmpty" @click.stop="clearData" class="custom-multiselect-clear">
        <svg v-svg symbol="modal-close" size="0 0 8 8" />
      </button>
      <button @click="toggleMenu" class="custom-multiselect-arrow" :class="isOpen ? 'open' : 'close'">
        <svg v-svg symbol="multiselect-arrow" size="0 0 12 6" role="info"></svg>
      </button>
    </div>
    <transition name="menu" :duration="300">
      <div v-show="isOpen" class="custom-multiselect-menu" :class="menuPosition">
        <div class="custom-multiselect-input-wrapper" v-if="searchBy">
          <input type="text" v-model.trim="searchValue" placeholder="Search" />
          <svg v-svg symbol="search" size="0 0 15 15" role="info"></svg>
        </div>
        <div
          class="custom-multiselect-list"
          :class="{
            full: this.value !== null && this.value.length >= this.maxLength && this.maxLength !== null,
          }"
          v-if="!treeSelect"
        >
          <div class="custom-multiselect-list__block" v-for="(el, key) of filteredList" :key="key" :class="checkElement(el) ? 'remove' : 'add'" @click="checkElement(el) ? removeElement(el) : addElement(el)">
            <slot name="list" :value="el"></slot>
          </div>
        </div>
        <div class="custom-multiselect-list" v-else>
          <div v-for="(el, key) of filteredTreeList" :key="key">
            <div
              class="custom-multiselect-list__block tree-parent"
              :class="{
                'no-tree': !el.children,
                add: !checkTreeElement(el.id),
                remove: checkTreeElement(el.id),
                'no-multiple': !multiply && el.children,
              }"
              @click="treeParentHandlerWrapper(el)"
            >
              <button @click.stop="toggleTreeChildList(el)" class="custom-multiselect-tree-arrow close" :ref="`arrow${el.id}`" v-if="el.children">
                <svg v-svg symbol="multiselect-arrow" size="0 0 12 6" role="info"></svg>
              </button>
              <label class="checkbox-wrapper" v-if="multiply">
                <span class="checkbox-wrapper__box treeselect" :class="{ active: checkTreeElement(el.id) }"> </span>
              </label>
              <slot name="tree-parent" :value="el"></slot>
            </div>
            <transition name="menu" :duration="300">
              <div :ref="`list${el.id}`" class="custom-multiselect-tree-list" v-show="showList(el.id)">
                <div
                  class="custom-multiselect-list__block tree-child"
                  v-for="(childEl, childKey) of el.children"
                  :class="{
                    remove: checkTreeElement(childEl.id),
                    add: !checkTreeElement(childEl.id),
                    group: childEl.groupValue,
                  }"
                  @click="treeChildHandlerWrapper(childEl, el)"
                  :key="childKey"
                >
                  <label class="checkbox-wrapper" v-if="multiply">
                    <span class="checkbox-wrapper__box treeselect" :class="{ active: checkTreeElement(childEl.id) }">
                      <!-- <font-awesome-icon
                        v-if="checkTreeElement(childEl.id)"
                        icon="check"
                      /> -->
                    </span>
                  </label>
                  <slot name="tree-child" :value="childEl"></slot>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import { cloneDeep, isEqual } from "lodash";

// Doesn't work with primitives (like string), only array of objects.

export default {
  name: "Multiselect",
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      required: true,
    },
    searchBy: {
      //a key of object to search by
      type: String,
      default: "",
    },
    allowEmpty: {
      //allows empty value or no
      type: Boolean,
      default: true,
    },
    multiply: {
      //makes a multiple value select
      type: Boolean,
      default: false,
    },
    alternativeStyle: {
      type: Boolean,
      default: false,
    },
    treeSelect: {
      //makes a tree type select
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
  },
  directives: {
    ClickOutside, //use 'click outside' to close select
  },
  data() {
    return {
      isOpen: false,
      searchValue: "",
      menuPosition: "", // to show a menu on the top or on the bottom
      //

      treeShowList: [], // use to save a open treeselect values with childrens
    };
  },
  created() {
    window.addEventListener("scroll", this.trackMenuPosition);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.trackMenuPosition);
  },
  computed: {
    filteredList() {
      if (this.searchValue.length === 0) {
        return this.list; //return default list if searchvalue if empty
      } else {
        return this.list.filter(
          (el) => el[this.searchBy].toLowerCase().includes(this.searchValue.toLowerCase()) //check if searchvalue includes in list elements and return it if yes
        );
      }
    },
    filteredTreeList() {
      if (this.searchBy.length === 0) {
        return this.list; //return default list if searchBy if empty
      } else {
        let filteredItems = [];

        for (let i = 0; i < this.list.length; i++) {
          const parent = this.list[i];
          let children = [];

          if (parent.children) {
            children = parent.children.filter((child) => {
              return child[this.searchBy].toLowerCase().includes(this.searchValue.toLowerCase()); //if parent have a children return a list of childens which includes searchBy value
            });
          }

          if (parent[this.searchBy].toLowerCase().includes(this.searchValue.toLowerCase())) {
            filteredItems.push({
              ...parent,
            }); //if parent includes searchBy value then return parent with all childrens
          } else if (children.length > 0) {
            filteredItems.push({
              ...parent,
              children: children,
            }); //in other case return only childrens which includes searchBy value
          }
        }

        return filteredItems;
      }
    },
  },
  methods: {
    toggleMenu(e) {
      if (this.isOpen) {
        e.stopPropagation();
      }
      this.isOpen = !this.isOpen;
    },
    toggleTreeChildList(el) {
      const arrow = this.$refs[`arrow${el.id}`][0];

      if (this.treeShowList.includes(el.id)) {
        const idx = this.treeShowList.indexOf(el.id);
        this.treeShowList.splice(idx, 1); //remove parent element from treeShowList array
        arrow.classList.remove("open");
        arrow.classList.add("close"); //puts arrow in the closed position
      } else {
        this.treeShowList.push(el.id); //add parent element from treeShowList array
        arrow.classList.remove("close");
        arrow.classList.add("open"); //puts arrow in the opened position
      }
    },
    showList(id) {
      return this.treeShowList.includes(id); //check if parent is open
    },
    trackMenuPosition() {
      const rect = this.$refs.block?.getBoundingClientRect() || 0;
      if (rect === 0) {
        this.menuPosition = "top";
        return;
      }
      if (rect.bottom + 250 <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.menuPosition = "";
      } else {
        this.menuPosition = "top"; //show list on top if it doesn't fit completely
      }
    },
    clearData() {
      this.$emit("input", []); //clear value property
    },
    checkTreeElement(id) {
      function findObject(obj, findId) {
        if (obj.id === findId) {
          // check if parent object id is equal

          return true;
        }

        if (obj.children) {
          for (const child of obj.children) {
            //check if any children id of parent objs is equal
            if (findObject(child, findId)) {
              return true;
            }
          }
        }

        return false;
      }

      if (Array.isArray(this.value)) {
        for (const obj of this.value) {
          //go through every obj in value property
          if (findObject(obj, id)) {
            return true;
          }
        }
        const block = this.list.find((el) => el.id === id);
        if (block && block.children) {
          return this.value.some(
            (item1) => block.children.some((item2) => item1.id === item2.id) //check if there are any children in value property
          );
        }
      } else {
        return this.value?.id == id;
      }

      return false;
    },
    removeTreeElement(parent, child) {
      if (!this.multiply) {
        if (this.allowEmpty) {
          this.$emit("input", null);
        }
        return;
      }
      const payload = cloneDeep(this.value);
      if (parent && !child) {
        const index = this.value.indexOf(this.checkElement(parent));
        if (index != -1) {
          if (this.value.length === 1 && !this.allowEmpty) return;
          this.remove(parent);
        } else {
          // if there is not a parent property in value then remove child property which were selected
          const removeIdx = this.value.filter((item1) => parent.children.some((item2) => item1.id === item2.id)).map((item) => item.id);
          removeIdx.forEach((element) => {
            const idx = payload.findIndex((el) => el.id === element);
            payload.splice(idx, 1);
          });
          if (payload.length === 0 && !this.allowEmpty) return;
          this.$emit("input", payload);
        }
      }
      if (child) {
        if (this.value.find((el) => isEqual(el, parent))) {
          //if there is a parent property in value
          const values = parent.children.filter((el) => {
            //makes a list of children except we click to remove
            return el.id !== child.id;
          });
          const parentIdx = payload.findIndex((el) => el.id === parent.id);

          payload.splice(parentIdx, 1); //remove a parent
          payload.push(...values); //push a list of children we create

          if (payload.length === 0 && !this.allowEmpty) return;
          this.$emit("input", payload);
        } else {
          if (this.value.length === 1 && !this.allowEmpty) return;
          this.remove(child);
        }
      }
    },
    findMatchingChild(parent) {
      //check if every children property is selected
      if (parent.children) {
        return parent.children.every((el) => JSON.stringify(this.value).includes(JSON.stringify(el)));
      } else return true;
    },
    treeParentHandlerWrapper(parent) {
      if (!this.multiply && parent.children) {
        this.toggleTreeChildList(parent);
      } else {
        if (this.checkTreeElement(parent.id)) {
          this.removeTreeElement(parent);
        } else {
          if (parent?.selectAllOption) {
            this.selectAllElements();
          } else {
            this.addTreeElement(parent);
          }
        }
      }
    },
    treeChildHandlerWrapper(child, parent) {
      if (this.checkTreeElement(child.id)) {
        this.removeTreeElement(parent, child);
      } else {
        this.addTreeElement(parent, child);
      }
    },
    selectAllSubParentElements(parent) {
      const result = parent.children.filter((el) => el.id !== -1);
      this.addArrayElements(result);
    },
    selectAllElements() {
      let allElements = this.list.filter((el) => el.children);
      let arr = [];
      allElements.forEach((el) => arr.push(el.children));
      let result = arr.flat().filter((el) => el.id !== -1);
      result.unshift({ id: 1, label: "Default" });
      this.$emit("input", result);
      this.isOpen = false;
    },
    addTreeElement(parent, child) {
      if (!this.multiply) {
        this.$emit("input", child || parent);
        return;
      }
      if (parent && !parent?.disable && !child) {
        this.add(parent);
      } else if (parent && parent?.disable && !child && parent.children) {
        this.selectAllSubParentElements(parent);
      } else if (parent && child) {
        let counter = 0;
        let maxCounter = parent.children.length;

        parent.children.forEach((el) => {
          const isMatch = this.value.some((element) => isEqual(el, element));
          if (isMatch) {
            counter++; //increase counter if there is a children value in our property
          }
        });
        if (counter === maxCounter - 1) {
          // if there are all children elements then we remove them and add a parent one
          const payload = cloneDeep(this.value);
          parent.children.forEach((el) => {
            const isMatch = this.value.some((element) => isEqual(el, element));
            if (isMatch) {
              const index = payload.indexOf(this.checkElement(el));
              payload.splice(index, 1); //remove every children element
            }
          });
          payload.push(parent);

          this.$emit("input", payload);
        } else {
          this.add(child);
        }
      }
    },
    checkElement(val) {
      if (Array.isArray(this.value)) {
        return this.value.find((el) => isEqual(el, val)); // check if there is an object in value property
      } else {
        return isEqual(this.value, val);
      }
    },
    removeElement(el) {
      if (Array.isArray(this.value)) {
        if (this.value.length === 1 && !this.allowEmpty) {
          // check if it can removes value
          return;
        }
        this.remove(el);
      } else {
        if (this.allowEmpty) {
          this.$emit("input", null);
          this.isOpen = false; // close menu on remove function
        }
      }
    },
    addElement(el) {
      if (Array.isArray(this.value)) {
        if (this.multiply) {
          if (this.maxLength == null || (this.maxLength !== null && this.value.length < this.maxLength)) {
            this.add(el);
          }
        } else {
          this.change(el);
        }
      } else {
        this.$emit("input", el);
        this.isOpen = false; // close menu on add function
      }
    },
    remove(element) {
      const index = this.value.indexOf(this.checkElement(element));
      const payload = cloneDeep(this.value);
      if (index != -1) {
        payload.splice(index, 1); //remove element from value
        this.$emit("input", payload);
      }
    },
    addArrayElements(elements) {
      const payload = cloneDeep(this.value);
      payload.push(...elements); //add elements to value

      this.$emit("input", payload);
    },
    add(element) {
      const payload = cloneDeep(this.value);
      payload.push(element); //add element to value

      this.$emit("input", payload);
    },
    change(element) {
      let payload = cloneDeep(this.value);
      payload[0] = element;

      this.$emit("input", payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes rotateArrow {
  from {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

@keyframes closeArrow {
  from {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}

@keyframes treeArrowClose {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  to {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
}

@keyframes treeArrowOpen {
  from {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  to {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
}

.menu-enter-active,
.menu-leave-active {
  transition: all 0.3s ease-out;
}
.menu-enter,
.menu-leave-to {
  transform: scaleY(0);
}

.custom-multiselect {
  width: 100%;
  position: relative;
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    .custom-multiselect-wrapper {
      pointer-events: none;
    }
  }
}

.custom-multiselect-wrapper {
  background: #f4f4f4;
  width: inherit;
  min-height: 42px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  color: #000;
  align-items: center;
  gap: 7px 10px;
  padding: 7px 40px 7px 8px;
  border-radius: 5px;
  transition: 0.3s all ease;

  &.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.clearable {
    padding-right: 58px;
  }

  &__text {
    font-weight: 500;
  }

  &__block {
    background: #4e5054;
    width: auto;
    max-width: -webkit-fill-available;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    gap: 0 5px;
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 5px;
    transition: 0.3s all ease;
    cursor: pointer;

    &.alternative {
      background: #ca4646;

      svg {
        fill: rgba(255, 255, 255, 0.6);
      }
    }

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.custom-multiselect-remove-button {
  border: none;
  outline: none;
  background: transparent;

  svg {
    fill: #9fa2a9;
  }
}

.custom-multiselect-tree-arrow {
  z-index: 1;
  border-radius: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: initial;

  svg {
    fill: #898989;
  }

  &:focus {
    outline: none;
  }

  &.close {
    svg {
      animation: treeArrowClose 0.3s ease forwards;
    }
  }

  &.open {
    svg {
      animation: treeArrowOpen 0.3s ease forwards;
    }
  }
}

.custom-multiselect-menu .custom-multiselect-list .custom-multiselect-list__block .custom-multiselect-tree-arrow {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.custom-multiselect-arrow {
  position: absolute;
  width: 26px;
  height: 26px;
  right: 8px;
  top: 7px;
  background: transparent;
  z-index: 20;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0;

  &:focus {
    outline: none;
  }

  svg {
    transform-box: fill-box;
    transform-origin: center;
  }

  &.open {
    svg {
      animation: rotateArrow 0.3s ease forwards;
    }
  }

  &.close {
    svg {
      animation: closeArrow 0.3s ease forwards;
    }
  }
}

.custom-multiselect-clear {
  font-size: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  background-repeat: no-repeat;
  position: absolute;
  right: 38px;
  top: 12px;
  color: #979797;
  transition: 0.3s all ease;

  &:hover,
  &:focus {
    color: #313131;
  }
}

.custom-multiselect-menu {
  position: absolute;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-top: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  z-index: 100;
  transform-origin: top;
  overflow: hidden;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  &.top {
    transform-origin: bottom;
    bottom: 100%;
    flex-direction: column-reverse;
  }
}

.custom-multiselect-tree-list {
  transform-origin: top;
}

.custom-multiselect-input-wrapper {
  position: relative;
  margin: 12px 0;
  padding-left: 14px;
  padding-right: 14px;
  input {
    width: 100%;
    border-radius: 5px;
    background: #f3f3f3;
    border: none;
    outline: none;
    min-height: 45px;
  }

  svg {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.custom-multiselect-list {
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;

  &.full {
    .custom-multiselect-list__block.add {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__block {
    display: block;
    padding: 15px 14px;
    font-size: 15px;
    line-height: 1;
    color: #141414;
    cursor: pointer;

    &.tree-parent,
    &.tree-child {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      padding: 8px 14px;

      .checkbox-wrapper {
        pointer-events: none;
      }
    }

    &.tree-child {
      padding-left: 64px;
      font-weight: 400;
    }

    &.tree-parent {
      font-weight: 500;
    }

    &.no-tree {
      padding-left: 42px;
    }

    &.add {
      &:hover,
      :focus {
        background: #66b587;
        color: #ffffff;
      }
    }

    &.remove {
      background-color: #f4f4f4;
      &:hover,
      :focus {
        background: #ce3e3e;
        color: #ffffff;
      }
    }

    &.group {
      pointer-events: none;
      color: #a9a9a9;
      padding-left: 48px;
    }

    &.tree-parent.no-multiple {
      &:hover,
      :focus {
        background: transparent;
        color: #141414;
      }
    }
  }
}
</style>
